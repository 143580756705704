import React, { useEffect, useState } from "react";
import * as Icons from "../../components/Global/Icons";
import RespondTable from "./RespondTable";
import { scrollToTop } from "../../utils/helper";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import * as XLSX from "xlsx";
import { useAuth } from "../../context/AuthContext";
import { columns } from "../constant";

interface ResponsesProps {
  fileLists: any;
}

const Responses: React.FC<ResponsesProps> = ({ fileLists }) => {
  const [startDate, setStartDate] = useState("");
  const { logout } = useAuth();
  const [endDate, setEndDate] = useState("");
  const [filteredRecords, setFilteredRecords] =
    useState<Array<Object>>(fileLists);
  const submitHandler = (e: any) => {
    e.preventDefault();
    let start = Math.floor(
      new Date(new Date(startDate).toLocaleDateString()).getTime() / 1000
    );
    let end = Math.floor(
      new Date(new Date(endDate).toLocaleDateString()).getTime() / 1000
    );
    console.log(start, end);
    const resultProductData = fileLists.filter((el: any) => {
      // let date = Math.floor(new Date(el?.["Submitted at"]).getTime() / 1000);
      let date = Math.floor(
        new Date(
          new Date(el?.["Submitted at"]).toLocaleDateString()
        ).getTime() / 1000
      );
      return date >= start && date <= end;
    });
    setFilteredRecords(resultProductData);
  };
  const renderHeader = () => {
    return (
      <>
        <form
          onSubmit={submitHandler}
          className="flex lg:flex-row items-center flex-col w-full gap-10 mt-16"
        >
          <div className="lg:w-1/2 w-full">
            <div className="flex items-center gap-2">
              <label className="input-title">Start Date:</label>

              <input
                type="date"
                placeholder="Start Date"
                name="startDate"
                className="px-3 h-10 w-60 border-[2px] border-black"
                required
                onChange={(e: any) => setStartDate(e.target.value)}
                value={startDate}
              />
            </div>
          </div>
          <div className="lg:w-1/2 w-full pt-2">
            <div className="flex items-center gap-2">
              <label className="input-title">End Date:</label>
              <input
                type="date"
                placeholder="Start Date"
                name="endDate"
                className="px-3 h-10 w-60 border-[2px] border-black"
                required
                onChange={(e: any) => setEndDate(e.target.value)}
                value={endDate}
              />
            </div>
          </div>
          <div className="lg:w-1/2 w-full pt-2">
            <button className="cursor-pointer" type="submit">
              <Icons.SubmitIcon />
            </button>
          </div>
        </form>
      </>
    );
  };

  const handleExportFile = () => {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(filteredRecords);
    XLSX.utils.book_append_sheet(wb, ws, "test");

    // Create a blob object from the workbook data
    const blob = new Blob([XLSX.writeFile(wb, "filter_combined.csv")]);

    // Create a URL for the blob
    const url = window.URL.createObjectURL(blob);

    // Create an anchor element to trigger the download
    const a = document.createElement("a");
    a.href = url;
    a.download = "test.csv";

    // Trigger a click event on the anchor element
    a.click();
  };
  const clearFilter = () => {
    setEndDate("");
    setStartDate("");
    setFilteredRecords(fileLists);
  };

  useEffect(() => {
    setFilteredRecords(fileLists);
  }, [fileLists]);

  return (
    <>
      <div className="lg:w-[60rem] mx-auto flex flex-col justify-center items-center lg:py-0 py-20 mt-10">
        <>
          <div
            id="table"
            className="lg:w-[60rem] mx-auto flex flex-col justify-center items-center lg:py-0 py-20"
          >
            <div className="w-full flex items-center justify-between">
              <h2 className="text-2xl font-semibold md:text-start text-center">
                Filter Data
              </h2>
              <div className="cursor-pointer" onClick={() => logout()}>
                <Icons.SignOut />
              </div>
            </div>
          </div>
        </>
        {renderHeader()}
        <div className="text-center w-full pt-2">
          <button
            onClick={handleExportFile}
            className="mr-5 bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center"
          >
            <svg
              className="fill-current w-4 h-4 mr-2"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
            </svg>
            <span>Export Filtered Data</span>
          </button>
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            onClick={clearFilter}
          >
            Clear Filter
          </button>
        </div>
      </div>

      <RespondTable columns={columns} fetchRowData={filteredRecords} />
    </>
  );
};

export default Responses;
