
// 
export const baseUrl = "https://6hghaaqo9c.execute-api.us-east-1.amazonaws.com"
// export const baseUrl = "http://localhost:4000"
export const JWT_TOKEN = "authorization"

export const columns = [
    { field: "Submission ID", header: "Submission ID" },
    { field: "Respondent ID", header: "Respondent ID" },
    { field: "Submitted at", header: "Submitted at" },
    { field: "BUCode", header: "BUCode" },
    { field: "Brand", header: "Brand" },
    { field: "BusinessType", header: "BusinessType" },
    { field: "FittingRoom", header: "FittingRoom" },
    { field: "survey_language", header: "Survey Language" },
    {
        field: "Please rate your experience today at STORE NAME",
        header: "Rated by STORE NAME",
    },
    {
        field: "What can be improved for a better experience?",
        header: "Improved for better experience",
    },
    {
        field: "What can be improved about our Value for Money?",
        header: "Improved Value for Money",
    },
    {
        field: "What can be improved about our Atmosphere?",
        header: "Atmosphere",
    },
    {
        field: "What can be improved about our Staff &amp; Service?",
        header: "Staff And Service",
    },
    {
        field: "What can be improved about our F&amp;B Quality?",
        header: "F&B Quality",
    },
    {
        field: "Did the manager visit your table?",
        header: "Manager Feedback",
    },
    {
        field: "What can be improved with our products?",
        header: "Products Feedback",
    },
    {
        field: "What can be improved about the store experience?",
        header: "Store Experinece",
    },
    {
        field: "What can be improved about our staff & service?_arabic",
        header: "Staff And Service",
    },
    {
        field: "What can be improved about our fitting rooms?",
        header: "Fitting Rooms Feedback",
    },
    { field: "NPS_en", header: "NPS_en" },
    {
        field: "How frequently do you visit us?",
        header: "Frequently Visit",
    },
    {
        field: "Do you have additional Feedback for us?",
        header: "Additional Feedback",
    },
    {
        field: "Please share your feedback here....",
        header: "Feedback",
    },
    { field: "First Name", header: "First Name" },
    { field: "Last Name", header: "Last Name" },
    { field: "email", header: "email" },
    { field: "Gender", header: "Gender" },
    { field: "phone", header: "phone" },
    { field: "DialCode (1)", header: "DialCode (1)" },
    { field: "Consent", header: "Consent" },
    { field: "NPS", header: "NPS" },
    { field: "Completion Status", header: "Completion Status" },
];


export const updateColumns = [
    { field: "BU", header: "BU Code" },
    { field: "Brand", header: "Brand" },
    { field: "Location", header: "Location" },
    { field: "Store", header: "Store" },
    { field: "Country", header: "Country" },
    { field: "Email1", header: "Email1" },
    { field: "Email2", header: "Email2" },
    { field: "Email3", header: "Email3" }
];
