import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { baseUrl, updateColumns } from "../constant";
import { useEffect, useState } from "react";
import UpdateRowModal from "../Modal/UpdateRowModal";
import { toaster } from "../../utils/helper";
import axios from "axios";
import Loader from "../Loader";

export default function UpdateTable({
  itemList,
  fetchRecoveryList,
}: {
  itemList: any;
  fetchRecoveryList: any;
}) {
  const [filteredRecords, setFilteredRecords] = useState(itemList);
  const [serach, setSerach] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [bucode, setBucode] = useState("");
  const [brand, setBrand] = useState("");
  const [location, setLocation] = useState("");
  const [store, setStore] = useState("");
  const [country, setCountry] = useState("");
  const [email1, setEmail1] = useState("");
  const [email2, setEmail2] = useState("");
  const [email3, setEmail3] = useState("");
  const [loader, setLoader] = useState(true);

  const filterHandler = (e: any) => {
    const serachVal = e.target.value;
    console.log(serachVal);
    if (serachVal) {
      const filter = itemList.filter(
        (data: any) =>
          serachVal
            ? Object.values(data).some((value) =>
                value
                  ?.toString()
                  .toLowerCase()
                  .includes(serachVal?.toLowerCase())
              )
            : itemList // If searchData is empty, include all objects
      );
      setFilteredRecords(filter);
    } else {
      setFilteredRecords(itemList);
    }
  };
  const tableSearch = () => (
    <div className="pt-2 relative mx-auto text-gray-600 text-end">
      <input
        className="border-2 border-gray-300 bg-white h-10 px-5 rounded-lg text-sm focus:outline-none"
        type="search"
        name="search"
        placeholder="Search"
        onChange={(e) => filterHandler(e)}
      />
    </div>
  );
  const rowClickHandler = (e: any) => {
    const { data } = e;
    if (data) {
      setBucode(data?.BU);
      setBrand(data?.Brand);
      setStore(data?.Store);
      setCountry(data?.Country);
      setLocation(data?.Location);
      setEmail1(data?.Email1);
      setEmail2(data?.Email2);
      setEmail3(data?.Email3);
      setShowModal(true);
    }
  };

  const submitHandler = async (e: any) => {
    e.preventDefault();
    if (!brand) {
      toaster("error", "Brand name is required");
      return;
    }
    if (!store) {
      toaster("error", "Store name is required");
      return;
    }
    if (!country) {
      toaster("error", "Country name is required");
      return;
    }
    if (!location) {
      toaster("error", "Location is required");
      return;
    }
    if (!email1) {
      toaster("error", "Email 1 is required");
      return;
    }
    if (!email2) {
      toaster("error", "Email 2 is required");
      return;
    }
    if (!email3) {
      toaster("error", "Email 3 is required");
      return;
    }
    setLoader(true);
    const updateUrl = `${baseUrl}/api/updateExcelFile`;
    const response = await axios.post(updateUrl, {
      brand,
      country,
      location,
      store,
      email1,
      email2,
      email3,
      bucode,
    });
    if (response.status === 200) {
      toaster("success", "Record Successfully updated");
      fetchRecoveryList();
      setShowModal(false);
    }
    setLoader(false);
  };

  useEffect(() => {
    setFilteredRecords(itemList);
    setLoader(false);
  }, [itemList]);
  return (
    <>
      {loader && <Loader />}
      {tableSearch()}
      <DataTable
        value={filteredRecords}
        paginator
        rows={5}
        tableStyle={{ minWidth: "50rem" }}
        rowHover
        onRowClick={rowClickHandler}
      >
        {updateColumns.map((col: any, i: any) => (
          <Column key={col.field} field={col.field} header={col.header} />
        ))}
      </DataTable>
      {showModal && (
        <div
          className={`justify-center items-center flex overflow-hidden overflow-y-auto fixed inset-0 z-10 outline-none focus:outline-none ${
            showModal ? "h-auto" : "h-0"
          } transition-all ease-in-out duration-500`}
        >
          <div className="relative w-auto my-6 mx-auto max-w-full">
            {/*content*/}
            <div
              className={`border-0 rounded-lg shadow-lg relative overflow-hidden flex flex-col bg-white outline-none focus:outline-none w-[37rem] ${
                showModal ? "h-auto" : "h-0"
              } transition-all ease-in-out duration-500`}
            >
              {/*header*/}
              <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                <h3 className="text-3xl font-semibold">Update File Data</h3>
                <button
                  className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                  onClick={() => setShowModal(false)}
                >
                  <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                    ×
                  </span>
                </button>
              </div>
              {/*body*/}
              <form onSubmit={submitHandler}>
                <div className="max-w-xl p-5">
                  <div className="flex items-center gap-2 mt-4">
                    <label className="input-title">Brand:</label>
                    <input
                      type="text"
                      name="brand"
                      placeholder="Enter the Brand Name"
                      className="px-3 h-10 w-60 border-[2px] border-black"
                      value={brand}
                      onChange={(e) => setBrand(e.target.value)}
                      required
                    />
                  </div>
                  <div className="flex items-center gap-2 mt-4">
                    <label className="input-title">Location:</label>
                    <input
                      type="text"
                      name="location"
                      value={location}
                      onChange={(e) => setLocation(e.target.value)}
                      placeholder="Enter the Location"
                      className="px-3 h-10 w-60 border-[2px] border-black"
                      required
                    />
                  </div>
                  <div className="flex items-center gap-2 mt-4">
                    <label className="input-title">Store:</label>
                    <input
                      type="text"
                      name="store"
                      value={store}
                      onChange={(e) => setStore(e.target.value)}
                      placeholder="Enter the Store Name"
                      className="px-3 h-10 w-60 border-[2px] border-black"
                      required
                    />
                  </div>
                  <div className="flex items-center gap-2 mt-4">
                    <label className="input-title">Country:</label>
                    <input
                      type="text"
                      name="country"
                      value={country}
                      onChange={(e) => setCountry(e.target.value)}
                      placeholder="Enter the Country Name"
                      className="px-3 h-10 w-60 border-[2px] border-black"
                      required
                    />
                  </div>
                  <div className="flex items-center gap-2 mt-4">
                    <label className="input-title">Email 1:</label>
                    <input
                      type="text"
                      name="email1"
                      value={email1}
                      onChange={(e) => setEmail1(e.target.value)}
                      placeholder="Enter the Email 1"
                      className="px-3 h-10 w-60 border-[2px] border-black"
                      required
                    />
                  </div>
                  <div className="flex items-center gap-2 mt-4">
                    <label className="input-title">Email 2:</label>
                    <input
                      type="text"
                      name="email2"
                      value={email2}
                      onChange={(e) => setEmail2(e.target.value)}
                      placeholder="Enter the Eamil 2"
                      className="px-3 h-10 w-60 border-[2px] border-black"
                      required
                    />
                  </div>
                  <div className="flex items-center gap-2 mt-4">
                    <label className="input-title">Email 3:</label>
                    <input
                      type="text"
                      name="email 3"
                      value={email3}
                      onChange={(e) => setEmail3(e.target.value)}
                      placeholder="Enter the Email 3"
                      className="px-3 h-10 w-60 border-[2px] border-black"
                      required
                    />
                  </div>
                </div>
                {/*footer*/}
                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                  <button
                    className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                    type="submit"
                    //   onClick={() => setShowModal(false)}
                  >
                    Update
                  </button>
                  <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setShowModal(false)}
                  >
                    Close
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
