import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { baseUrl } from "../components/constant";
import { useAuth } from "../context/AuthContext";
import * as Icons from "../components/Global/Icons";
import UpdateTable from "../components/UpdateTable";
import Loader from "../components/Loader";

const HomePage: React.FC = () => {
  const { logout } = useAuth();

  const [itemList, setItemList] = useState([]);
  const url = `${baseUrl}/api/allBucode`;
  const fetchRecoveryList = useCallback(async () => {
    const apiRequest = await axios.get(url);
    const data = apiRequest.data?.data;
    setItemList(data);
  }, []);
  React.useEffect(() => {
    fetchRecoveryList();
  }, []);
  return (
    <>
      <div
        id="table"
        className="lg:w-[60rem] mx-auto flex flex-col justify-center items-center py-20"
      >
        <div className="w-full flex items-center justify-between">
          <h2 className="text-2xl font-semibold md:text-start text-center">
            VOC
          </h2>
          <div className="cursor-pointer" onClick={() => logout()}>
            <Icons.SignOut />
          </div>
        </div>
      </div>
      <UpdateTable itemList={itemList} fetchRecoveryList={fetchRecoveryList} />
    </>
  );
};

export default HomePage;
