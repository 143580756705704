import React, { useCallback, useContext, useState } from "react";
import Responses from "../components/Responses";
import Recovery from "../components/Recovery";
import RespondToCustomers from "../components/RespondToCustomers";
import { useLocation } from "react-router-dom";
import { baseUrl } from "../components/constant";
import ResponseContext from "../components/Context";
import axios from "axios";

interface IResonseToCustomer {
  respondToCustomerCount: number;
  allResponseCount: number;
  viewNewResponsesCount: number;
}
interface IRequestObject {
  data: IResonseToCustomer;
}
const BuCodeData: React.FC = () => {
  const [itemList, setItemList] = useState({});
  //   const history;
  const location = useLocation();
  const url = `${baseUrl}/api/allResponses`;
  const tableUrl = `${baseUrl}`;
  let [fileLists, setFieLists] = useState([]);

  const fetchRecoveryList = useCallback(async () => {
    const apiRequest = await axios.get(url);
    const data = apiRequest.data?.data;
    setFieLists(data);
  }, []);
  React.useEffect(() => {
    fetchRecoveryList();
  }, []);
  return (
    <>
      <Responses fileLists={fileLists} />
    </>
  );
};

export default BuCodeData;
