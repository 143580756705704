import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import axios from "axios";
import { baseUrl } from "../constant";
import ResponseContext from "../Context";
import moment from "moment";
import { ExcelDateToJSDate } from "../../utils/helper";
import * as Icons from "../../components/Global/Icons";
import { Calendar } from "primereact/calendar";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

interface ResponsesProps {
  fetchRowData: Array<Object>; // Replace with the actual type of fetchRowData
  columns: any;
}

const RespondTable: React.FC<ResponsesProps> = ({ fetchRowData, columns }) => {
  const [filteredRecords, setFilteredRecords] =
    useState<Array<Object>>(fetchRowData);

  useEffect(() => {
    console.log(fetchRowData);
    setFilteredRecords(fetchRowData);
  }, [fetchRowData]);

  return (
    <>
      <DataTable
        value={filteredRecords}
        paginator
        rows={5}
        tableStyle={{ minWidth: "50rem" }}
      >
        {columns.map((col: any, i: any) => (
          <Column key={col.field} field={col.field} header={col.header} />
        ))}
      </DataTable>
      {/* <table className="mt-20 ">
        <thead>
          <tr className="bg-table-header">
            {columns?.map((el: any, index: any) => (
              <th key={index} className="table-title">
                {el?.header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {filteredRecords?.length
            ? filteredRecords?.map((el: any, index: any) => {
                return (
                  <tr key={index}>
                    <td>{el?.["Submission ID"]}</td>
                    <td>{el?.["Respondent ID"]}</td>
                    <td>{el?.["Submitted at"]}</td>
                    <td>{el?.["BUCode"]}</td>
                    <td>{el?.["Brand"]}</td>
                    <td>{el?.["BusinessType"]}</td>
                    <td>{el?.["FittingRoom"]}</td>
                    <td>{el?.["survey_language"]}</td>
                    <td>
                      {el?.["Please rate your experience today at STORE NAME"]}
                    </td>
                    <td>
                      {el?.["What can be improved for a better experience?"]}
                    </td>
                    <td>
                      {el?.["What can be improved about our Value for Money?"]}
                    </td>
                    <td>
                      {el?.["What can be improved about our Atmosphere?"]}
                    </td>
                    <td>
                      {
                        el?.[
                          "What can be improved about our Staff &amp; Service?"
                        ]
                      }
                    </td>
                    <td>
                      {el?.["What can be improved about our F&amp;B Quality?"]}
                    </td>
                    <td>{el?.["Did the manager visit your table?"]}</td>
                    <td>{el?.["What can be improved with our products?"]}</td>
                    <td>
                      {el?.["What can be improved about the store experience?"]}
                    </td>
                    <td>
                      {
                        el?.[
                          "What can be improved about our staff & service?_arabic"
                        ]
                      }
                    </td>
                    <td>
                      {el?.["What can be improved about our fitting rooms?"]}
                    </td>
                    <td>{el?.["How frequently do you visit us?"]}</td>
                    <td>{el?.["Do you have additional Feedback for us?"]}</td>
                    <td>{el?.["Please share your feedback here...."]}</td>
                    <td>{el?.["First Name"]}</td>
                    <td>{el?.["Last Name"]}</td>
                    <td>{el?.["email"]}</td>
                    <td>{el?.["Gender"]}</td>
                    <td>{el?.["phone"]}</td>
                    <td>{el?.["DialCode (1)"]}</td>
                    <td>{el?.["Consent"]}</td>
                    <td>{el?.["NPS"]}</td>
                    <td>{el?.["Completion Status"]}</td>
                  </tr>
                );
              })
            : ""}
        </tbody>
      </table> */}
    </>
  );
};

export default memo(RespondTable);
